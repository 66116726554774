<template>
    <vx-card id="desired-stores" class="vs-con-loading__container">
        <router-link to="/app/indicators" class="float-right text-info">Ver más</router-link>
        <h4 class="text-info">Tiendas más deseadas</h4><br/>

        <vs-table :data="desiredStores" :noDataText=" this.$t( 'Datos no disponibles' ) ">
            <template slot="thead">
                <vs-th>NO.</vs-th>
                <vs-th>Tienda</vs-th>
                <vs-th>Clasificación</vs-th>
                <vs-th></vs-th>
            </template>

            <template slot-scope="{data}">
                <vs-tr v-bind:key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].id">
                        <router-link to="/">{{ data[indextr].id }}</router-link>
                    </vs-td>

                    <vs-td :data="data[indextr].name">
                        {{ data[indextr].name }}
                    </vs-td>

                    <vs-td :data="data[indextr].score">
                        <vs-progress :height="12" :percent="(data[indextr].score * 100) / 5"  :color="calculateRatingColor(data[indextr].score)"></vs-progress>
                    </vs-td>

                    <vs-td :data="data[indextr].score">
                        <b class="text-dark">{{ data[indextr].score | fixedFloat(0) }}</b>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </vx-card>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    data() {
        return {
            desiredStores: []
        }
    },

    mounted() {
        this.getReport();
    },

    watch : {
        rangeDateSelected() {
            this.getReport();
        }
    },

    methods: {
        calculateRatingColor( rating ) {
            if( rating < 3) {
                return 'danger';
            } else if( rating >= 3 && rating < 4 ) {
                return 'warning';
            } else if ( rating >= 4) {
                return 'success';
            }
        },

        getReport() {
            this.$vs.loading({
                color: 'info',
                container: "#desired-stores",
                scale: 0.65
            });

            this.$store.dispatch( 'indicators/getShopsReports' )
                .then( res => {
                    this.$vs.loading.close("#desired-stores > .con-vs-loading");
                    const data = res.data;

                    this.setData( data );
                });
        },

        setData( data=[] ) {
            const desiredStores = [];
            data.forEach( (store, index) => {
                desiredStores.push( {
                    id    : index + 1,
                    name  : store["shop_name"],
                    score : store["qualifications"],
                } );
            });

            this.desiredStores = desiredStores;            
        },
    },

    computed : {
        rangeDateSelected : {
            get() {
                return this.$store.state.indicators.rangeDateSelected;
            },
            set( val ) {
                this.$store.commit( 'indicators/SET_RANGE_DATE_SELECTED', val );
            }
        }
    },
})
</script>