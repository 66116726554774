<!-- =========================================================================================
  File Name: OpinionCard.vue
  Description: Opinion Card
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla administativa - aliados
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
========================================================================================== -->

<template>
    <vx-card id="with-loading" class="vs-con-loading__container">
        <div class="flex overflow-x-auto ">
            <div class="sm:hidden lg:block bg-greenLight w-1/2 inline-block rounded text-center">
                <h5 class="pt-5 mb-4">Tus Calificaciones</h5>

                <vue-apex-charts
                    type="radialBar"
                    height="200px"
                    :options="radialBarChart.chartOptions"
                    :series="radialBarChart.series"
                ></vue-apex-charts>

                <p class="text-xs mb-10">Esta es la calificación promedio de nuestros clientes hacia tus tiendas</p>
            </div>

            <div class="lg:w-5/6 xs:w-full inline-block pl-5">
                <div class="w-full flex justify-end">
                    <router-link to="/app/indicators" class="text-info">Ver más</router-link><br/><br/>
                </div>

                <h5 class="text-info">Como te ven</h5>
                <p class="mb-5">Opiniones</p>

                <div v-if="opinions.length === 0">
                    <i>No hay datos disponibles para ésta consulta</i>
                </div>

                <div class="flex mb-1" v-for="opinion in opinions" v-bind:key="opinion.id">
                    <div class="w-full">
                        <p class="text-xs">{{opinion.comment}}</p>
                    </div>

                    <div class="flex w-full">
                        <vs-progress :height="12" :percent="(opinion.rating * 100) / 5"  :color="calculateRatingColor(opinion.rating)"></vs-progress>
                        <b class="ml-10">{{ opinion.rating }}</b>
                    </div>
                </div>
            </div>
        </div>                            
    </vx-card>
</template>

<script lang="ts">
import VueApexCharts from 'vue-apexcharts';

import Vue from 'vue'
export default Vue.extend({
    data() {
        return {
            radialBarChart: {
                series: [0],
                chartOptions: {
                    colors: ['#28C76F'],
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                size: '61%',
                            },
                            dataLabels: {
                                name: {
                                    offsetY: -10,
                                    show: false,
                                    color: "#888",
                                    fontSize: "13px"
                                },
                                value: {
                                    color: "#111",
                                    fontSize: "30px",
                                    show: true
                                }
                            }
                        }
                    },
                    stroke: {
                        lineCap: 'round'
                    },
                    // labels: ["Progress"]
                },
            },

            opinions: [
            ],
        }
    },

    mounted() {
        this.getReport();
    },

    watch : {
        rangeDateSelected() {
            this.getReport();
        }
    },

    methods: {
        calculateRatingColor( rating ) {
            if( rating < 3) {
                return 'danger';
            } else if( rating >= 3 && rating < 4 ) {
                return 'warning';
            } else if ( rating >= 4) {
                return 'success';
            }
        },
        getReport() {
            this.$vs.loading({
                color: 'info',
                container: "#with-loading",
                scale: 0.65
            });            

            this.$store.dispatch( 'indicators/getQuestionReports' )
                .then( res => {
                    this.$vs.loading.close("#with-loading > .con-vs-loading");
                    const data = res.data;

                    this.setData( data );
                });
        },

        setData( data=[] ) {
            if( data.length === 0) {
                this.radialBarChart.series = [];
                this.radialBarChart.series.push( 0 );
                this.opinions = [];
                return;
            }
            
            const dataSingle = data[0];

            // Radial bar chart percentage
            this.radialBarChart.series = [];
            this.radialBarChart.series.push( dataSingle['qualification'].toFixed(2) );            

            const answers = dataSingle['answers'];
            const newOpinions = [];

            answers.forEach( (answer, index) => {
                // const rating = answer["num_orders"] / answer["sum_questions"];
                const rating = answer["sum_questions"];
                newOpinions.push( {
                    id      : index + 1,
                    comment : answer["name"],
                    rating,
                } );
                
                
            });

            this.opinions = newOpinions;
            
        },
    },

    computed : {
        rangeDateSelected : {
            get() {
                return this.$store.state.indicators.rangeDateSelected;
            },
            set( val ) {
                this.$store.commit( 'indicators/SET_RANGE_DATE_SELECTED', val );
            }
        }
    },

    components: {
        VueApexCharts,
    }
})
</script>