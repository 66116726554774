<template>
    <vx-card class="mt-12 stores-card">
        <div class="flex justify-between">
            <span class="text-5xl text-primary"><b>{{ stores.length }}</b></span>

            <div class="pt-2 pl-6">
                {{ this.$t('Tiendas') }}<br/> {{ this.$t('en total') }}
            </div>

            <div class="w-3/4">
                <router-link to="/app/stores" class="text-info float-right">{{ this.$t('Ver más') }}</router-link><br/>
            </div> 
        </div>

        <div class="pt-2">
            <GmapMap
                :center="center"
                :zoom="defaultZoom"
                style="height: 30vh; width: 100%"
                ref="googleMap"
            >
                <GmapMarker
                    v-bind:key="index"
                    v-for="(m, index) in markers"
                    :position="m.position" 
                    :clickable="true"
                    :draggable="false"
                    @click="center=m.position"
                    :icon="{
                        url: require('@/assets/icons/MarkerIcon.png'),
                        size: {width: 40, height: 40, f: 'px', b: 'px'},
                        scaledSize: {width: 40, height: 40, f: 'px', b: 'px'},
                    }"
                />
            </GmapMap>
        </div>

        <h5 class="mt-5 text-info"><b>{{ this.$t('Listado de tiendas') }}</b></h5>

        <vs-table :max-items="15" pagination v-model="selected" :data="stores" class="mt-5" :noDataText="this.$t('Datos no disponibles')">
            <template slot="thead">
                <vs-th>{{ this.$t('Nombre de Tienda') }}</vs-th>
                <vs-th>{{ this.$t('Dirección') }}</vs-th>
            </template>

            <template slot-scope="{data}">
                <vs-tr v-bind:key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].name">
                        <span @click="handleSelected( data[indextr].id )" class="cursor-pointer"> {{ data[indextr].name }} </span>
                    </vs-td>

                    <vs-td :data="data[indextr].address">
                        {{ data[indextr].address }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </vx-card>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({

    data() {
        return {
            selected: [],
            center: { lat: -10.613263, lng: -51.799295, },
            defaultZoom: 3.1,
        }
    },

    created() {
        this.$store.dispatch( 'stores/getAllStores' );
    },

    methods: {
        handleSelected( storeId ) {
            const store = this.stores.find( store => store.id === storeId );
            
            this.center = {
                lat: store.cy,
                lng: store.cx,
            };

            this.defaultZoom = 15;
            const mapRef = this.$refs.googleMap;
            const top = mapRef.offsetTop;
            window.scrollTo( 0, top );
            
        },
    },

    computed: {
        stores() {
            return this.$store.state.stores.stores;
        },

        markers() {
            let markers = [];

            this.stores.forEach( store => {
                let a = { "position": { "lat": store.cy, "lng": store.cx } }
                return markers.push(a);
            });

            return markers;
        },
    },

});
</script>

<style lang="scss">
.stores-card{
    .con-pagination-table { visibility: hidden !important; }
}
</style>
