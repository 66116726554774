<!-- =========================================================================================
  File Name: MainDashboard.vue
  Description: Main Dashboard
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla administativa - aliados
  Author: Miguel A. Arenas
  Author URL: http://github.com/mxaxaxbx
========================================================================================== -->

<template>
    <div>
        <div class="vx-row">
            <div class="vx-col lg:w-3/4 sm:w-full mt-3">
                    <!-- <div class="vx-col w-full mt-5">
                        <div class="flex justify-between">
                            <h3 class="text-info"><b>Resumen</b></h3>

                            <vs-dropdown vs-trigger-click class="cursor-pointer">
                                <vs-button color="white" text-color="#000" class="w-64">
                                    <p class="inline-block align-middle ">
                                        <img :src="require(`@/assets/icons/CalendarIcon.svg`)" width="16px" />&nbsp;
                                    </p>&nbsp;

                                    <span class="text-gray">{{ getNameOfRangeSelected( rangeDateSelected ) }}</span>&nbsp;

                                    <p class="inline-block align-middle text-gray">
                                        <feather-icon icon="ChevronDownIcon" ></feather-icon>
                                    </p>
                                </vs-button>
                                
                                <vs-dropdown-menu>
                                    <vs-dropdown-item v-for="dateRange in dateRanges" v-bind:key="dateRange.id" @click=" rangeDateSelected = dateRange.id " class="w-32">
                                        {{ dateRange.name }}
                                    </vs-dropdown-item>
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </div>
                    </div> -->
                    
                    <!-- <div class="vx-col w-full lg:w-1/2 mt-5">
                        <opinion-card></opinion-card>
                    </div> -->

                    <!-- <div class="vx-col w-full lg:w-1/2 mt-5">
                        <desired-stores-card></desired-stores-card>
                    </div> -->

                    <div class="vx-col w-full mt-1">
                        <router-link to="/app/orders/" class="float-right text-info">
                            <feather-icon icon="ChevronRightIcon" class="inline-block align-middle"></feather-icon>&nbsp;
                            {{ this.$t('Ir a mis pedidos') }}
                        </router-link>
                        <h3 class="text-info"><b>{{ this.$t('Historial de Pedidos') }}</b></h3>

                        <orders-history-card></orders-history-card>
                    </div>
            </div>

            <div class="vx-col lg:w-1/4 sm:w-full mt-4">
                <h3 class="text-info"><b>{{ this.$t('Tiendas') }}</b></h3>
                <stores-card></stores-card>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue     from 'vue';
import Cookies from 'js-cookie';

import OpinionCard       from './OpinionCard.vue';
import DesiredStoresCard from './DesiredStoresCard.vue';
import OrdersHistoryCard from '../orders/OrdersHistoryCard.vue';
import StoresCard        from './StoresCard.vue';

export default Vue.extend({
    data() {
        return {
            interval: null,
        }
    },

    created() {
        this.$store.dispatch( 'updatePageTitle', {
            title: this.$t('Bienvenido ') + this.activeUserInfo.person.name,
            subtitle: this.$t(`a tu plataforma administrativa`),
        });
        this.createInterval();
    },

    destroyed() {
        clearInterval( this.interval );
    },

    methods: {
        getNameOfRangeSelected( rangeId ) {
            const range = this.dateRanges.find( range => range.id === rangeId );
            if( !range ) return '';
            return range.name;
        },
        createInterval() {
            const effectiveType = navigator['connection']['effectiveType'];
            const userInfo = JSON.parse( Cookies.get('userInfo') );
            const role     = userInfo['auth_grants']['role'];
            
            if( role !== 'GOTU' || effectiveType !== '4g' ){
                clearInterval( this.interval );
                return;
            }

            clearInterval( this.interval );
            this.interval = setInterval( () => {
                console.log('comprobando red...');
                this.$store.dispatch( 'checkService' );
                this.$store.dispatch( 'checkSitiService' );
            }, 60000 ); // 60000 1 minute
            
        },
    },

    computed: {
        activeUserInfo() {
          return this.$store.state.AppActiveUser
        },
        dateRanges : {
            get() {
                return this.$store.state.indicators.dateRanges;
            }
        },
        rangeDateSelected : {
            get() {
                return this.$store.state.indicators.rangeDateSelected;
            },
            set( val ) {
                this.$store.commit( 'indicators/SET_RANGE_DATE_SELECTED', val );
            }
        }
    },

    components: {
        OpinionCard,
        DesiredStoresCard,
        OrdersHistoryCard,
        StoresCard,
    },
})
</script>
